import React from 'react'
import GradientBartop from '../Containers/GradientBarTop'
import PrimaryHeading from '../../General/Headings/PrimaryHeading'
import InlineWImage from '../Cards/InlineWImage'
import Button from '../Buttons/Button'
import SmartLink from '../SmartLink'

const ArticleList = ({ title, articles, link }) => {
  return (
    <GradientBartop>
      {title && (
        <PrimaryHeading level="2" classesList="mb-4">
          {title}
        </PrimaryHeading>
      )}
      <div>
        {/* list of articles */}
        {articles &&
          articles.map((article) => (
            <div className="mb-2" key={article.key}>
              <InlineWImage {...article}></InlineWImage>
            </div>
          ))}
      </div>
      {link && (
        <SmartLink to={link.url} className="lg:w-1/3 no-underline">
          <Button arrowFlushRight>{link.text}</Button>
        </SmartLink>
      )}
    </GradientBartop>
  )
}

export default ArticleList
