import React from 'react'
import classnames from 'classnames'

import SmartLink from '../SmartLink'

const InlineWImage = ({ date, title, link, category }) => {
  return (
    <div className={classnames('w-full', 'md:flex', 'md:items-center')}>
      <div className="py-2">
        <p
          className={classnames(
            'text-green-3',
            'text-xs',
            'font-extrabold',
            'font-sans',
            'pb-1'
          )}
        >
          {date} |
        </p>
        <h3
          className={classnames(
            'text-3xl',
            'font-bold',
            'font-sans',
            'leading-tight'
          )}
        >
          <SmartLink
            to={link.url}
            className={classnames(
              'text-charcoal',
              'hover:underline',
              'no-underline'
            )}
          >
            {title}
          </SmartLink>
        </h3>
      </div>
    </div>
  )
}

export default InlineWImage
