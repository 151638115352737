import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import classnames from 'classnames'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import SectionContainer from '../../General/SectionContainer'
import SecondaryHeading from '../../General/Headings/SecondaryHeading'

const Underline = styled.div`
  ${tw`
    h-px
    mx-auto
    block
  `}
  width: 50px;
`

const ResourceArchive = ({ title, children, className, noPadding }) => {
  return (
    <PageWrap bg={false}>
      <SectionContainer
        className={`${!noPadding ? 'pt-3 pb-5' : ''} ${className}`}
      >
        <SecondaryHeading
          className={classnames(
            'font-sans',
            'text-charcoal',
            'tracking-wide',
            'uppercase',
            'text-center',
            'pb-2',
            'mt-5',
            'font-black',
            'text-3xl'
          )}
        >
          {title}
        </SecondaryHeading>
        <div>
          <Underline></Underline>
        </div>
      </SectionContainer>
      {children}
    </PageWrap>
  )
}

export default ResourceArchive
