import React from 'react'
import styled from 'styled-components'

const Bar = styled.div`
  height: 8px;
`

const GradientBartop = ({ children }) => {
  return (
    <div className="bg-white card-shadow h-full relative">
      <Bar className="bg-green-3"></Bar>
      <div className="px-2 py-4 lg:px-18 lg:py-8">{children}</div>
    </div>
  )
}

export default GradientBartop
