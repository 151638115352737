import React from 'react'
import { withPreview } from 'gatsby-source-prismic'

// components
import ArticleQuery from '../components/Templates/ArticleQuery'
import ArticleList from '../components/General/ArticleList'

const GetArticles = ({ cards }) => (
  <div className="flex flex-wrap my-4 items-stretch">
    <div className="w-full">
      {cards && cards.length > 0 && <ArticleList articles={cards} />}
    </div>
  </div>
)

const News = ({ pageContext }) => {
  const pageData = {
    title: 'Latest News',
    locale: pageContext.locale,
    path: pageContext.name,
  }

  return (
    <ArticleQuery {...pageData}>
      <GetArticles />
    </ArticleQuery>
  )
}

export default withPreview(News)
