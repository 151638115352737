import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Prismic from '@prismicio/client'
import ReactPaginate from 'react-paginate'
import PrismicDOM from 'prismic-dom'
import className from 'classnames'
import { screen } from '../../../utils'
import formatDate from '../../../utils/formatDate'
// import Checkbox from '../../General/CheckBox'

// components
import ResourceArchive from '../../../components/Templates/ResourceArchive'
import SectionContainer from '../../../components/General/SectionContainer'
const apiEndpoint = process.env.GATSBY_PRISMIC_API_ENDPOINT
const apiToken = process.env.GATSBY_PRISMIC_API_TOKEN

const transformArticle = (article) => {
  const { data } = article

  let namespace = data.category

  if (!namespace) {
    namespace = data.article_type
  }

  namespace = namespace
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()

  const localUrl = `/resources/${namespace}/${article.uid}`

  let url = ''
  let external = false

  if (data.external_link && data.external_link.url) {
    external = true
    url = data.external_link.url
  } else {
    url = localUrl
  }

  return {
    key: article.id,
    image: {
      src: data.featured_image?.url,
      alt: 'Testing alt',
    },
    title: data.title[0].text,
    authorPrefix: 'By',
    author: data.authors_name,
    description: PrismicDOM.RichText.asText(data.excerpt),
    category: data.category,
    button: {
      route: url,
      text: 'Learn More',
      external,
    },
    link: {
      url,
      text: 'Learn More',
    },
    date: formatDate(data.date),
  }
}

const PaginationWrapper = styled.div`
  .pagination {
    ${tw`
      my-4
      flex
      justify-center
      list-reset
      font-sans
      font-light
    `}

    ${screen('lg')} {
      ${tw`
        mt-6
      `}
    }

    li a {
      ${tw`
        flex
        items-center
        justify-center
      `}
      padding: 0.4rem 0.75rem;
      line-height: 1;
    }

    li {
      ${tw`
        flex
        items-center
        justify-center
      `}
    }

    li.active {
      ${tw`
        bg-black
        text-white
        rounded-sm
      `}
    }
  }
`

const ArticleQuery = ({ category, title, locale, children, path }) => {
  const isClient = !(typeof window === 'undefined')

  const [isLoading, setLoading] = useState(true)
  const [articles, setArticles] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [ignoreScroll, setIgnoreScroll] = useState(true)

  const archive = {
    title,
    locale,
    path,
  }
  let page = 0
  const client = Prismic.client(apiEndpoint, {
    accessToken: apiToken,
  })

  // make sure children have access to the articles queried
  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { cards: articles })
    }
    return child
  })

  const fetchArticles = () => {
    setLoading(true)

    // create query for prismic
    const predicates = []
    predicates.push(Prismic.Predicates.at('document.type', 'article'))
    if (category) {
      predicates.push(Prismic.Predicates.at('my.article.category', category))
    }

    client
      .query(predicates, {
        lang: locale,
        page: page + 1,
        pageSize: 12,
        orderings: '[my.article.date desc]',
        graphQuery: `
            {
              article {
                ...articleFields
              }
            }
          `,
      })
      .then((resp) => {
        setArticles(
          resp.results &&
            resp.results.map((article) => transformArticle(article))
        )
        setPageCount(resp.total_pages)
        setLoading(false)
      })
  }

  const handlePageClick = (data) => {
    page = data.selected
    fetchArticles()
    if (!ignoreScroll) {
      document.querySelector('#article-container').scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      setIgnoreScroll(false)
    }
  }

  // on intial setup run our queries
  useEffect(() => {
    if (isClient) {
      fetchArticles()
    }
  }, [])

  return (
    <ResourceArchive {...archive} noPadding className="pt-3 pb-0 md:pb-1">
      <SectionContainer id="article-container">
        <div
          className={className('min-h-half-screen', {
            'opacity-50': isLoading,
          })}
        >
          {childrenWithProps}
          {!isLoading && articles.length === 0 && (
            <h3 className="text-center py-3">No Results</h3>
          )}
        </div>
        <PaginationWrapper>
          <ReactPaginate
            initialPage={page}
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            }
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            }
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </PaginationWrapper>
      </SectionContainer>
    </ResourceArchive>
  )
}

export default ArticleQuery
